'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var modeleKevin = void 0;

$(document).ready(function () {
    Config.template = 'KEVIN';
    Config.image_url = '/images/' + Config.template + '/';
    Config.mapStyleType = 'kevin';
    modeleKevin = new ModeleKevin();

    $(document).on('opened.zf.offcanvas', '.off-canvas', function () {
        $('header .menu-icon').addClass('open');
    }).on('closed.zf.offcanvas', '.off-canvas', function () {
        $('header .menu-icon').removeClass('open');
    });
});

var ModeleKevin = function () {
    function ModeleKevin() {
        _classCallCheck(this, ModeleKevin);

        this.pubHome = $('#campaign-home');
        this.init();
        this.initSelect();
        ChosenTouch.init();
    }

    _createClass(ModeleKevin, [{
        key: 'init',
        value: function init() {
            var _this = this;

            $(document).foundation();
            if (this.pubHome.css('display')) {
                var img = new Image();
                img.onload = function () {
                    if (BrowserInfo.isMobile) _this.resizePub();
                    _this.pubHome.css({
                        'margin-top': '-' + (_this.pubHome.height() / 2 + 1) + 'px',
                        'display': 'block'
                    });
                    _this.pubHome.removeClass('hidden');
                };
                img.src = this.pubHome.find('img').attr('src');
            }
            this.bindUIActions();
        }
    }, {
        key: 'initSelect',
        value: function initSelect() {
            var SELECTS = [{ 'select': $("select.villemoteur") }, { 'select': $("select.villemoteur1") }, { 'select': $("select.moteurlightvillebien") }, { 'select': $("select.typemoteur") }, { 'select': $("select.typemoteur1") }],
                i;
            for (i = 0; i < SELECTS.length; i++) {
                SELECTS[i]['select'].select2({
                    placeholder: SELECTS[i]['placeholder'],
                    dropdownCssClass: "bigdrop",
                    allowClear: true
                });
            }
        }
    }, {
        key: 'bindUIActions',
        value: function bindUIActions() {
            var _this2 = this;

            $('.ui-cookies').on("click", function () {
                $(document.getElementById('cookies')).css({
                    '-webkit-transform': 'translateY(100%)',
                    '-ms-transform': 'translateY(100%)',
                    'transform': 'translateY(100%)'
                });
            });
            $('#search').on("click", function (e) {
                e.preventDefault();
                _this2.updateSelect2([$("select.villemoteur"), $("select.typemoteur")], [$("select.villemoteur1").val(), $("select.typemoteur1").val()]);
                $("#submit").trigger('click');
                _this2.resetData();
            });
            $('.chosen-select').chosen({ search_contains: true });
            $('.select2').select2();
            if ($('#list').css('display')) {
                if ($('#map').length) {
                    GoogleMap.init();
                }
                $('.menu-icon').on("click", function () {
                    var innerWrapper = $('.off-canvas-wrapper-inner'),
                        overflow = innerWrapper.css('overflow');
                    setTimeout(function () {
                        innerWrapper.css('overflow', overflow == 'hidden' ? 'visible' : 'hidden');
                        $('.off-canvas-content').css('backface-visibility', overflow == 'hidden' ? 'visible' : 'hidden');
                    }, overflow == 'hidden' ? 0 : 500);
                });
            }
            $('p.read-more').readmore({
                speed: 75,
                lessLink: '<a href="#">En lire moins</a>',
                moreLink: '<a href="#">En lire plus</a>',
                collapsedHeight: 160
            });
            $('#property').css('display') ? $('#choco-gallery').Chocolat({
                imageSize: 'cover',
                loop: true,
                firstImage: 0,
                imageSelector: '.chocolat-image',
                enableZoom: false
            }) : null;

            // Si page d'accueil
            if (window.page == 'index') {
                var paddingSectionAvis = parseFloat($('.sectionAvis .layer').css('padding')) * 2;
                var lineWidth = (parseFloat($('.sectionAvis .layer').css('width')) - paddingSectionAvis) / 2;
                $('.sectionAvis .layer .circleleftline, .sectionAvis .layer .circlerightline').css('width', lineWidth - 35.5);
                $(window).resize(function () {
                    var paddingSectionAvis = parseFloat($('.sectionAvis .layer').css('padding')) * 2;
                    var lineWidth = (parseFloat($('.sectionAvis .layer').css('width')) - paddingSectionAvis) / 2;
                    $('.sectionAvis .layer .circleleftline, .sectionAvis .layer .circlerightline').css('width', lineWidth - 35.5);
                });
            } else {

                // Menu header toggle
                $('#headerTop').on('click', '[data-pane="close"]', function () {
                    $(this).hide();
                    $('#headerTop [data-pane="open"]').show();
                    $('#headerTop .engine').toggle(300);
                    $('#headerTop').removeClass('open').addClass('close');
                    $('body').removeClass('menuSearchEngineOpen'); // Savoir que le menu est ouvert ou pas.
                    // $('#headerTop').animate({
                    //     'height': '65px'
                    // }, 300)
                });
                $('#headerTop').on('click', '[data-pane="open"]', function () {
                    $(this).hide();
                    $('#headerTop [data-pane="close"]').show();
                    $('#headerTop .engine').toggle({
                        duration: 300,
                        complete: function complete() {
                            $('#headerTop select').trigger('chosen:updated');
                            // $('select').trigger('liszt:updated');
                        }
                    });
                    $('#headerTop').removeClass('close').addClass('open');
                    $('body').addClass('menuSearchEngineOpen'); // Savoir que le menu est ouvert ou pas.
                });

                $('select.tri-format').on('change', function () {
                    window.open(this.options[this.selectedIndex].value, '_self');
                });
            }
        }
    }, {
        key: 'getTransac',
        value: function getTransac(type_transac, sold_stc) {
            var transacs = {
                'vente': function vente() {
                    return sold_stc != '' ? '<span class="label bg-violet">' + sold_stc + '</span>' : '<span class="label bg-green">Vente</span>';
                },
                'location': function location() {
                    return '<span class="label bg-green">Location</span>';
                },
                'vendu': function vendu() {
                    return '<span class="label bg-red">Vendu</span>';
                },
                'default': function _default() {
                    return null;
                }
            };
            return (transacs[type_transac] || transacs['default'])();
        }
    }, {
        key: 'titleCase',
        value: function titleCase(str) {
            var i = void 0;
            str = str.toLowerCase().split(' ');
            for (i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(' ');
        }
    }, {
        key: 'displayProperties',
        value: function displayProperties(data, id_display) {
            var _this3 = this;

            $.each(data, function (key, value) {
                var exclu_content = !value.exclu ? '' : '<span class="exclu">' + value.exclu + '</span>',
                    label = value.type_transac === 'vendu' ? '<span class="label margin5 round bg-rouge">' + value.sold_date + '</span>' : value.sold_stc ? '<span class="label bg-violet lb-desc">' + value.sold_stc + '</span>' : value.under_offer ? '<span class="label bg-violet sous-offre lb-desc">' + value.under_offer + '</span>' : '',
                    transac = _this3.getTransac(value.type_transac, value.sold_stc),
                    piece = value.rooms && value.rooms !== '-' && value.rooms !== 0 ? '<div class="dimension"><img src="' + Config.image_url + ('/home.png" class="picto"/><span class="values">' + value.rooms + '</span></div>') : '',
                    price = value.type_transac === 'vendu' ? '<span class="price-sold">Prix: ' + value.price + '</span>' : '<span class="price">' + value.price + '</span>',
                    html = '<article id="' + value.id + '" class="columns medium-offset-0 large-offset-0 small-offset-1 small-10 small-10 medium-12 large-12 ui-property" style="position: relative;opacity:0;">\n                            <a href="' + value.link + '">\n                                <div class="columns large-7 medium-4 small-12 picture" style="background-image:url(' + value.image + (');">\n                                    ' + exclu_content + '\n                                    <span class="show-for-small-only overlay">\n                                        <h2>' + _this3.titleCase(value.type) + '</h2>\n                                        <h6>' + value.city + '</h6>\n                                    </span>\n                                </div>\n                                <div class="columns large-5 medium-8 small-12 content">\n                                    <div class="sous-content">\n                                        ' + label + '\n                                        <h2 class="hide-for-small-only">' + _this3.titleCase(value.type) + '</h2>\n                                        <h6 class="hide-for-small-only">' + value.city + '</h6>\n                                        <br/>\n                                        <div class="dimensions">\n                                            ' + piece + '\n                                            <div class="dimension">\n                                                <img src="') + Config.image_url + ('/measuring-tape.png" class="picto"/><span class="values">' + value.surface + '</span>\n                                            </div>\n                                        </div>\n                                        ' + price + '\n                                    </div>\n                                </div>\n                            </a>\n                </article>');
                $('#' + id_display).append(html);
                $('#' + id_display + ' > article#' + value.id).delay((key + 1) * 50).animate({
                    'opacity': '1'
                }, 230);
            });
        }
    }]);

    return ModeleKevin;
}();
