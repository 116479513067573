(function() {
	var app = angular.module('engine', []);
	app.controller('mainController', function($scope) {
		this.forms = {
			typebien: null,
			town: null,
			budgetmin: null,
			budgetmax: null
		}
	});
	app.directive('chosen', function($timeout) {

	  var linker = function(scope, element, attr) {

	    scope.$watch('store.forms', function() {
	      $timeout(function() {
	        element.trigger('chosen:updated');
	      }, 0, false);
	    }, true);

	    $timeout(function() {
	      element.chosen();
	    }, 0, false);
	  };

	  return {
	    restrict: 'A',
	    link: linker
	  };
	});
})();
